window.$ = window.jQuery = require('jquery');
import Cookies from 'js-cookie'
window.Swal = require('sweetalert2');
(function($, customMsg, Cookies) {
  require('air-datepicker');
  require('air-datepicker/dist/js/i18n/datepicker.'+App.locale);
  require("jquery-mousewheel");
  require('malihu-custom-scrollbar-plugin');
  let $body = $('body');
  let $gotop = $('.gotop');
  let isMobile = false;
  let topbarStatus = 1;
  if (Cookies.get('topbarStatus')) {
    topbarStatus = Cookies.get('topbarStatus');
  }
  if (topbarStatus < 0) {
    $body.addClass('topbar-hide')
  }
  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) isMobile = true;

  // IE關閉smooth scroll
  if(navigator.userAgent.match(/Trident\/7\./)) {
    document.body.addEventListener("mousewheel", function() {
      event.preventDefault();
      let wd = event.wheelDelta;
      let csp = window.pageYOffset;
      window.scrollTo(0, csp - wd);
    });
  }

  $(document).ready(function() {
    $('[name=same_as_account]').on('change', function(event) {
      var a = $(this).data('a');
      var b = $(this).data('b');
      if ($(this).is(':checked')) {
        $(a).on('keyup', function(event) {
          $(b).val($(a).val());
        }).keyup();
      } else {
        $(a).off('keyup');
      }
    }).change();
    $('.has-error').on('change', '.field', function(event) {
      if ($(this).val()!='') {
        $(this).parents('.has-error').removeClass('has-error')
      }
    });
    $('.main').css('min-height', $(window).height() - ($('.nav-toggle').is(':visible')?$('.header').outerHeight():0) - $('.footer').outerHeight())
    // 日期選擇套件
    $('.dp').datepicker({
      language: App.locale,
      dateFormat: 'yyyy-mm-dd',
      // todayButton: true,
      onHide: function(dp, animationCompleted){
        if (animationCompleted && dp.$el.val()) {
          dp.selectDate(new Date(dp.$el.val()))
        }
      }
    });
    $('.dp-y').datepicker({
      language: App.locale,
      view: 'years',
      minView: 'years',
      dateFormat: 'yyyy',
      // todayButton: true,
      onHide: function(dp, animationCompleted){
        if (animationCompleted && dp.$el.val()) {
          dp.selectDate(new Date(dp.$el.val()))
        }
      }
    });
    let menuOpenSpeed = 300;
    $('.topbar-toggle').click(function(event) {
      $body.toggleClass('topbar-hide');
      topbarStatus *= -1;
      Cookies.set('topbarStatus', topbarStatus)
      return false;
    });
    $('.nav-toggle').click(function(event) {
      $body.toggleClass('nav-open');
      $('.quick-menu li.open').removeClass('open');
      return false;
    });
    $('.member-toggle, .search-toggle').click(function(event) {
      $(this).parent().toggleClass('open');
      $(this).parent().siblings().removeClass('open');
      return false;
    });
    $('.subscribe-toggle').click(function(event) {
      $(this).parent().toggleClass('open');
      return false;
    });
    // $('.side-title').click(function(event) {
    //   $(this).parent('.side-sect').toggleClass('active');
    // });
    $('.menu li, .side-nav-list li').has('ul').addClass('has-child');
    $('.menu li.has-child').hover(function(event) {
      if (!isMobile) {
        $(this).addClass('open');
        // $(this).children('ul').stop().slideDown(menuOpenSpeed);
        // $(this).css('height', 'auto');
      }
    }, function() {
      if (!isMobile) {
        $(this).removeClass('open');
        // $(this).children('ul').stop().slideUp(menuOpenSpeed);
      }
    });
    $('.menu li.has-child > a').click(function(event) {
      if (isMobile) {
        $(this).parent().toggleClass('open');
        $(this).parent().siblings('li').removeClass('open');
        // $(this).siblings('ul').stop().slideToggle(menuOpenSpeed);
        // $(this).parent().siblings('li').children('ul').stop().slideUp(menuOpenSpeed);
        // $(this).parents('li').css('height', 'auto');
        if (!$(this).parent().hasClass('open')) {
          $(this).siblings('ul').find('li').removeClass('open');
          // $(this).siblings('ul').find('ul').stop().slideUp(menuOpenSpeed);
        }
        return false;
      }
    });
    $(document).on('click', function(e){
      if($(e.target).parents('.menu').length==0){
        $('.menu li.has-child').removeClass('open');
        // $('.menu li.has-child > ul').stop().slideUp(menuOpenSpeed);
      }
      if($(e.target).parents('.subscribe').length==0){
        $('.subscribe').removeClass('open');
      }
      if($(e.target).parents('.quick-menu .member').length==0){
        $('.quick-menu .member').removeClass('open');
      }
      if($(e.target).parents('.quick-menu .search').length==0){
        $('.quick-menu .search').removeClass('open');
      }
      if($(e.target).parents('.nav').length==0){
        $body.removeClass('nav-open');
      }
      if($(e.target).parents('.msg-box').length==0 || $(e.target).hasClass('msg-box-close') || $(e.target).parents('.msg-box-close').length > 0){
        $body.removeClass('msg-box-open');
        $('.msg-box').removeClass('show');
      }
    }).on('click', '.msg-box-toggle', function(event) {
      var t = $(this).attr('href');
      $('.msg-box').removeClass('show');
      $(t).addClass('show');
      $body.addClass('msg-box-open');
      return false;
    });
    $('.side-nav-toggle').click(function(event) {
      $(this).toggleClass('active');
    });
    $('.side-nav-list.js-toggle-child li').has('ul').addClass('has-child');
    $('.side-nav-list.js-toggle-child li.has-child > a').click(function(event) {
      $(this).parent().toggleClass('open');
      $(this).siblings('ul').stop().slideToggle(menuOpenSpeed);
      $(this).parents('li').css('height', 'auto');
      if (!$(this).parent().hasClass('open')) {
        $(this).siblings('ul').find('li').removeClass('open');
        $(this).siblings('ul').find('ul').stop().slideUp(menuOpenSpeed);
      }
      return false;
    });

    $gotop.click(function(event) {
      $('html,body').stop().animate({
          scrollTop: 0
      }, 400);
      return false;
    });
    $('.btn.scroll').click(function(event) {
      var t = $(this).attr('href');
      $('html,body').stop().animate({
          scrollTop: $(t).offset().top
      }, 400);
      return false;
    });
  });
  var $side = $('.side');
  $(window).on('scroll resize', function(event) {
    if ($('.nav-toggle').is(':hidden') && $side.is(':visible')) {
      var listTopPos = $('.content').offset().top - $('.header').outerHeight();
      var listPos = $('.content').offset().top + $('.content').outerHeight() - $side.outerHeight(true) - $('.header').outerHeight();
      if ($(window).scrollTop() < listPos &&  $(window).scrollTop() > listTopPos) {
        var txtTop = $(window).scrollTop() - listTopPos;
      } else if ($(window).scrollTop() < listTopPos) {
        var txtTop = 0;
      }
      $side.css('top', txtTop);
    } else {
      $side.css('top', 0);
    }
  }).scroll();

  if ($('.floating-subscribe').length) {
    let subscribeStatus = 1;
    // Cookies.set('subscribeStatus', subscribeStatus)
    if (Cookies.get('subscribeStatus')) {
      subscribeStatus = Cookies.get('subscribeStatus');
    }
    if (subscribeStatus < 0) {
      $body.removeClass('subscribe-show')
    } else {
      $body.addClass('subscribe-show')
    }
    $('.subscribe-toggle').click(function(event) {
      $body.toggleClass('subscribe-show');
      subscribeStatus *= -1;
      Cookies.set('subscribeStatus', subscribeStatus)
      return false;
    });
  }

  $(window).on('load', function() {
    if ($('.main-top').length > 0 && !window.location.hash) {
      $('html,body').stop().animate({
          scrollTop: $('.main-content').offset().top
      }, 600);
    } else if (window.location.hash) {
      $('html,body').stop().animate({
        scrollTop: $(window.location.hash).offset().top
      }, 600);
    }
  });
  function topbarSetHeight() {
    if ($body.hasClass('has-topbar')) {
      $('.topbar-holder').css('height', $('.topbar .container').outerHeight());
    }
  }
  $(window).on('load', topbarSetHeight);
  $(window).on('resize', topbarSetHeight);

  $(window).on('scroll', function(event) {
    if ($(window).scrollTop() > 50) {
      $body.addClass('header-fixed');
    } else {
      $body.removeClass('header-fixed');
    }
    if ($('.floating-subscribe').length) {
      if ($(window).scrollTop() > ($body.height()-$(window).height()-$('.footer').outerHeight())*0.66) {
        $body.addClass('scroll-pass');
      } else {
        $body.removeClass('scroll-pass');
      }
    }
  }).scroll();

  let lastScrollTop = $(window).scrollTop();
  let st = $(window).scrollTop();
  $(window).on('load', function() {
    $('.anime').each(function () {
      let currSect = $(this);
      $('.anime').each(function () {
        let currSect = $(this);
        if (currSect.offset().top - $(window).height() <= st) {
          currSect.addClass("anime-run");
        }
      });
    });
  });
  $(window).on('scroll', function(event) {
    st = $(window).scrollTop();
    if (st > lastScrollTop){
      $('.anime').each(function () {
        let currSect = $(this);
        if (currSect.offset().top - $(window).height()*0.8 <= st && currSect.offset().top + $(window).height()*0.8 > st) {
          currSect.addClass("anime-run");
        }
      });
    } else {
      $('.anime').each(function () {
        let currSect = $(this);
        if (currSect.offset().top - $(window).height()*0.5 <= st && currSect.offset().top > st) {
          currSect.addClass("anime-run");
        }
      });
    }
    lastScrollTop = st;
  });

  $('.display-toggle').each(function(index, el) {
    var targetName = $(this).data('target');
    var targetValue = $(this).data('target-val').toString();
    var targetValueArr = targetValue.split(",");
    var $target = $('[name='+targetName+']');
    // console.log(targetName, targetValue, targetValueArr, targetValueArr.includes($(this).val()));
    $target.on('change', function(event) {
      if (targetValueArr.includes($(this).val())) {
        $('.display-toggle[data-target="'+targetName+'"][data-target-val="'+targetValue+'"]').addClass('show');
        if ($target.is('input[type=radio]')) {
          var subName = $('[name='+targetName+'][data-sub]').data('sub');
        } else if ($target.is('select')) {
          var subName = $target.find('[data-sub]').data('sub');
        }
        if (subName) {
          $('[name='+subName+']').trigger('change');
        }
      } else {
        $('.display-toggle[data-target="'+targetName+'"][data-target-val="'+targetValue+'"]').removeClass('show')
        $('.display-toggle[data-target="'+targetName+'"][data-target-val="'+targetValue+'"]').find('[type="radio"], [type="checkbox"]').prop('checked', false);
        $('.display-toggle[data-target="'+targetName+'"][data-target-val="'+targetValue+'"]').find('.form-control').val('');
      }
    });
  });
})($, customMsg, Cookies);
var customMsg;
window.customMsg = function(title, txt) {
  if ($('.result').length > 0) {
    var inner =
    `<div class="result-title">${title}</div>
    <div class="result-txt">${txt}</div>
    <div class="result-btn">
      <button class="btn btn-default result-close" type="button">確認</button>
    </div>`;
    $('.result-inner').html(inner);
  } else {
    var html =
    `<div class="result">
      <div class="result-content">
        <button class="btn result-close-btn result-close" type="button"><i class="ic ic-close"></i></button>
        <div class="result-inner">
          <div class="result-title">${title}</div>
          <div class="result-txt">${txt}</div>
          <div class="result-btn">
            <button class="btn btn-default result-close" type="button">確認</button>
          </div>
        </div>
      </div>
    </div>`;
    $('body').append(html);
  }
  $(document).on('click', function(e){
    if($(e.target).parents('.result').length==0 || $(e.target).parents('.result-close').length>0 || $(e.target).hasClass('result-close')){
      $('body').removeClass('result-open');
    }
  });
  $('body').addClass('result-open');
};
